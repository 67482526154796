
/* .leader-board-routing-section{
    overflow: hidden !important;
    padding-bottom: 0.6em;
    height: 100vh ;
} */

/* .timer-container{
    background-color: #6000d2;
    color: white;
    padding: 10px 10px;
    font-family: 'Gellix' !important;
    border-radius: 16px;
  } */
  

/*  
  .leader-board-slider-vdo{
    width: 46.5vw;
    border-radius: 25px !important;
    
  } */

  /* @media (min-width: 2000px) {
    .leader-board-routing-section{
        overflow: hidden !important;
        padding-bottom: 5em;
        height: 100vh;
    }
    .leader-board-slider-vdo {
        object-fit: cover; 
        border-radius: 25px; 
      }
  } */
  
  .leader-board-routing-section{
    overflow: hidden !important;
    height: 100vh;
  }

  video{
    width: auto !important;
    height: 310px !important;
    border-radius: 25px;
    box-shadow: 0px 0px 20px 0px #00000045;
}

.timer-container{
    background-color: #6000d2;
    color: white;
    padding: 10px 10px;
    font-family: 'Gellix' !important;
    border-radius: 16px;
  }

  
  .leader-board-footer-container {
    background-color: #6000d2;
    color: white;
    padding: 10px;
    font-family: 'Gellix' !important;
    overflow: hidden !important;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  .marquee-item {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between image and text */
    margin-right: 100px; /* Space between the sets */
  }
  
  .leader-board-footer-kama-icon {
    height: 30px;
  }
  
  .leader-board-footer-text {
    font-size: 2vh;
    white-space: nowrap;
  }
  

  

  @media (min-width: 1500px) and (max-width: 2560px) {

    .timer-container{
      margin-top: 2em;
    }
    .leader-board-table-vdo-row{
      margin-top: 4em !important;
    }

    .leader-board-footer-container{
      height: 9%;
      width: 100%;
      position: fixed !important;
      bottom: 0;
      display: flex;
      overflow: hidden !important;
      align-items: center;
    }

    .marquee-item{
      font-size: 5vh !important;
      font-family: 'Gellix';
      white-space: nowrap;
    }

    video{
      width: auto !important;
      height: 48% !important;
      border-radius: 25px;
      box-shadow: 0px 0px 20px 0px #00000045;
  }
  }