/* Responsive Navigation Bar */
.leader-board-navigation-bar-section {
    background: #ffffff40;
    justify-content: space-between;
    z-index: 1;
    box-shadow: 0px 0px 43px 0px #8a57ea;
    padding-left: 4em;
    padding-right: 4em;
  }
  
  .leader-board-navigation-bar-kama-logo {
    height: 60px;
  }
  
  .leader-board-navigation-bar-ifx-logo {
    height: 38px;
    margin-right: 20px; /* Add space between IFX logo and logout icon */
  }
  
  .logout-section {
    display: flex;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
  }
  
  
  
  
  
  @media (min-width: 1500px) and (max-width: 2560px) {
    .leader-board-navigation-bar-kama-logo {
      height: 100px;
    }
    
    .leader-board-navigation-bar-ifx-logo {
      height: 78px;
      margin-right: 20px; /* Add space between IFX logo and logout icon */
    }
  }