/* 
  
  

  .headerStyle{
    background-color: #6000d9 !important;
    color: whitesmoke !important;
    font-family: 'Gellix' !important;
    font-weight: bold !important;
    font-size: 3.5vh !important;
    height:90% !important;
    text-align: center !important;
  }

 

  .leader-board-index-table{
    width: 45vw;
    
  }

  .leader-board-index-table-cell{
    font-family: "Gellix" !important;
    font-size: 4.7vh !important; 
    text-align: center !important;
  }
  

  @media (min-width: 2000px) {
    
    .leader-board-index-table{
      font-family: "Gellix" !important;
      font-size: 1.8rem !important; 
      text-align: center !important;
    }
    .table-container {
      max-width: 100%;
      margin: auto;
    }
  
    .headerStyle {
      font-size: 2rem !important; 
      padding: 20px !important;
    }
  
    .leader-board-index-table-cell {
      font-size: 2rem !important;
      padding: 20px !important;
      text-align: center !important;
    }
  
    .leader-board-user-image {
      width: 87px !important;
      height: 87px !important;
    }
  
    TableRow {
      height: 100px !important;
    }
  }
   */



   
   
   
   

    .headerStyle{
    background-color: #6000d9 !important;
    color: whitesmoke !important;
    font-family: 'Gellix' !important;
    font-weight: bold !important;
    font-size: 2.5vh !important;
    height:90% !important;
    text-align: center !important;
  }

  .leader-board-index-table-cell{
    font-family: "Gellix" !important;
    font-size: 2.3vh !important; 
    text-align: center !important;
    font-weight: 500 !important;
    padding-top: 4.5px !important;
    padding-bottom: 5px !important;
  }

   .leader-board-user-image{
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  @media (min-width: 1500px) and (max-width: 2560px) {
    .leader-board-index-setion{
      padding-left: 4em !important;
    }
    .leader-board-user-image{
      height: 80px;
      width: 80px;
      border-radius: 50%;
    }

    .leader-board-index-table-cell{
    font-family: "Gellix" !important;
    font-size: 2.5vh !important;
    text-align: center !important;
    font-weight: 500 !important;
    padding-top: 5.8px !important;
    padding-bottom: 5.8px !important;
  }
  } 